import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// 引入全局样式
import './assets/css/global.less'
// 视频摄像头css
import 'video.js/dist/video-js.css'
// vueX
import store from './store'
// 引入nopogress
import Noprogress from 'nprogress'
import 'nprogress/nprogress.css'
//高德离线地图
import './plugins/aMap.js'
// 富文本
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)

// 测试地址
// var basURLStr = 'http://anquan.sxpz.vip/GovApiTest/'
var basURLStr = 'http://anquan.sxpz.vip/GovApi/'
var instance = axios.create()
if (process.env.NODE_ENV == 'development') {
  instance.defaults.baseURL == process.env.VUE_APP_BASEURL
} else if (process.env.NODE_ENV == 'production') {
  instance.defaults.baseURL == basURLStr
}
instance.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV == 'production') {
    config.url = basURLStr + config.url.split(/\/api\//)[1]
  }
  Noprogress.start()
  return config
})
instance.interceptors.response.use(config => {
  Noprogress.done()
  return config;
})
var instance1 = axios.create()


Vue.prototype.$axios = instance
Vue.prototype.$http = instance1
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
